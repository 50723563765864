// Edit Modal
.edit-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 45rem;
	height: 23rem;
	transform: translate((-50%, -50%));
	background-color: white;
	padding: 3rem;
	z-index: 1000;
	box-shadow: 0rem 0.3rem 1.2rem black;
	border-radius: 0.8rem;

	.edit-title {
		text-align: center;
	}

	.job-name {
		margin-top: 2rem;
		margin-bottom: 1rem;
		.name-box {
			width: 99%;
			height: 3rem;
			padding: 0.2rem;
			margin-top: 0.5rem;
		}
	}

	.job-priority {
		select {
			width: 101%;
			height: 3.4rem;
			padding: 0.5rem;
			margin-top: 0.5rem;
		}
	}

	.action {
		display: flex;
		justify-content: space-around;
		margin-top: 2rem;

		button {
			font-size: 1.5rem;
			width: 11rem;
			height: 4rem;
		}
	}
}

// All input and select
input,
select {
	padding-left: 0.8rem;
	border: 1px solid gray;
	border-radius: 0.4rem;
}
