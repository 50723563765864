// Create Job
.add-job-title {
	margin-top: 3rem;
}

.create-job {
	margin-top: 2rem;

	.form {
		display: flex;
		align-items: center;
		gap: 2rem;

		.error {
			color: red;
			border-color: red;

			&::placeholder {
				color: red;
			}
		}

		.job-name {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			width: 75%;

			input {
				height: 3rem;
			}
		}

		.job-priority {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			width: 15%;

			select {
				height: 3.6rem;
			}
		}

		.submit-btn {
			width: 10%;

			label {
				visibility: hidden;
			}

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 3.5rem;
			}
		}
	}
}
