// Delete modal
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: gray;
	opacity: 0.7;
	z-index: 1000;
}

.delete-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 45rem;
	height: 20rem;
	transform: translate((-50%, -50%));
	background-color: white;
	padding: 2rem;
	z-index: 1000;
	box-shadow: 0rem 0.3rem 1.2rem black;
	border-radius: 0.8rem;

	.delete-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		.delete-icon {
			svg {
				width: 10rem;
				height: 10rem;
			}
		}
	}

	.delete-action {
		display: flex;
		justify-content: space-around;
		margin-top: 2rem;

		button {
			font-size: 1.5rem;
			width: 11rem;
			height: 4rem;
		}
	}
}
