// Footer
.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 7rem;
	color: rgb(97, 96, 96);
	background-color: #f5f5f5;
	font-size: 1.5rem;

	.git {
		padding-left: 2rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;

		.box {
			color: #fc664d;
			padding: 0.4rem;
			border-radius: 0.3rem;
			background-color: #ffe6e2;
		}

		.repo {
			a {
				text-decoration: none;
				color: rgb(97, 96, 96);
			}
		}
	}

	.copyright {
		padding-right: 2rem;
	}
}
