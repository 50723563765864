@import "../pages/indexPage.scss"; // Index Page

@import "../components/Header/header.scss"; // Header

@import "../components/Footer/footer.scss"; // Footer

@import "../components/Create-Job/createJob.scss"; // Create Job

@import "../components/Job-List/jobList.scss"; // Job List

@import "../components/Delete-Modal/deleteModal.scss"; // Delete Modal

@import "../components/Edit-Modal/editModal.scss"; // Edit Modal

@import "./responsive.scss"; // Responsive

html {
	font-size: 10px; // 1rem = 10px
}

body {
	font-size: 15px;
	font-family: "Lato", sans-serif;
}
